@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Arimo:wght@400;500&display=swap');

html, body, #root, .content {
    height: 100%;
    width: 100%;
    margin: 0;
}

body {
    box-sizing: border-box;
    background-color: #131313;
    color: #e4e4e4;
    font-family: 'Inter', sans-serif;
    margin: 0;
}

svg {
    pointer-events: none;
}

/* UTILITY - BUTTONS */
.btn {
    align-items: center;
    background-color: #248aff;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: none;
    padding: 10px;
    cursor: pointer;
    color: #e4e4e4;
    transition: background-color .3s, box-shadow .3s;
    font-family: 'Arimo', sans-serif;
    font-weight: 500;
    font-size: 1em;
}

.btn a {
    text-decoration: none;
    color: #fff;
}

.btn-large {
    font-size: 1.5em;
}

.btn-small {
    padding: 7px 10px 7px 10px;
    font-weight: 500;
}

.btn:hover {
    background-color: #006ae2;
}

.btn:active {
    box-shadow: none;
}

.btn-alt {
    background-color: #fff;
    color: #006ae2;
}

.btn-alt:hover {
    background-color: #cecece;
}

.btn-delete {
    background-color: #dd2e2e;
}

.btn-delete:hover {
    background-color: #ad1f1f;
}

.btn-grey {
    background-color: #2d2d2f;
    color: #e4e4e4;
}

.btn-grey:hover, .btn-task-modal:hover {
    background-color:#232324;
}

.btn-task-modal {
    background-color: #2f2f2f;
    display: flex;
    align-items: center;
    color: #e4e4e4;
}

.btn-close {
    width: 32px;
    height: 32px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}

.btn-close::before {
    content: "\00d7";
    font-size: 30px;
    font-weight: 400;
}

.btn-svg {
    position: relative;
    height: 18px;
    width: 18px;
    padding-right: 7px;
    padding-bottom: 1px;
}

.btn-await {
    background-color: #006ae2;
}

.btn-wide {
    padding: 9px 17px;
}

/* END UTILITY - BUTTONS */

/* UTILITY - NAV */
.navbar {
    top: 0;
    background-color: #19191d;
    position: sticky;
    z-index: 1;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4);
}

.navbar-content {
    max-width: 1024px;
    max-height: 60px;
    margin: 0px auto;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    gap: 20px;
}

.site-logo {
    padding: 0px 8px;
    font-family: 'Inter', sans-serif;
}

.site-logo sup {
    font-size: 10px;
    color: #bf3b34;
    padding-left: 10px;
}

.nav-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.navbar a {
    color: inherit;
    text-decoration: none;
}

.profile-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin: 0px 0px 0px auto;
}

.profile-button {
    width: 30px;
    height: 30px;
    background: none;
    border: none;
    cursor: pointer;
}

.profile-image {
    width: 30px;
    height: 30px;
    background-color: #6ab556;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    pointer-events: none;
}

.profile-image p {
    color: #131313;
    margin: 0;
    font-family: 'Arimo', sans-serif !important;
    font-size: 14px;
    font-weight: bold;
}

.profile-dropdown {
    position: absolute;
    background-color: #19191c;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4);
    padding: 10px;
    border: 1px solid #2b2b2b;
    border-radius: 10px;
    top: 55px;
    z-index: 10;
    font-family: 'Arimo', sans-serif;
}

.profile-dropdown-heading {
    font-size: 12px;
    padding-bottom: 5px;
    padding-left: 5px;
    border-bottom: 1px solid #2b2b2b;
}

.profile-preview {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid #2f2f2f;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    gap: 10px;
}

.profile-preview-info {
    display: flex;
    flex-direction: column;
}

.profile-preview-info p {
    margin: 0;
    padding: 2px;
}

.profile-dropdown-button {
    background-color: #19191c;
    border: none;
    outline: none;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    padding: 10px 8px;
    margin: 5px 0px;
    font-size: 16px;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    transition: background-color .1s;
}

.profile-dropdown-button:hover {
    background-color: #2f2f2f;
}

.app-navbar {
    top: 0;
    width: 100%;
    background-color: #131313;
    position: sticky;
    z-index: 1;
    border-bottom: 1px solid #2b2b2b;
}

.app-navbar-content {
    padding: 0px 8px;
    max-height: 50px;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
}

.app-navbar a {
    color: white;
    text-decoration: none;
}
/* END UTILITY - NAV */

/* UTILITY - MODAL */
.modal-backdrop {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: rgba(0,0,0,.8);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.modal-content {
    position: relative;
    width: 600px;
    min-height: 600px;
    margin: 100px 10px;
    padding: 25px;
    border-radius: 10px;
    background-color: #131313;
    border: 1px solid #2b2b2b;
}

.modal-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin: 4px;
    transform: translate(-20px, 20px);
}

.modal-close::before {
    content: "\00d7";
    font-size: 32px;
    font-weight: 400;
    color: #d2d2d2;
}

.inline-modal-content {
    position: relative;
    border-radius: 10px;
    background-color: #222222;
    margin: 0 auto;
}

/* END UTILITY - MODAL */

/* UTILITY - POPOUT */

.popout {
    position: absolute;
    margin-top: 3em;
    display: inline-flex;
}

.popout-content {
    display: block;
    position: absolute;
    background-color: #19191c;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid #2b2b2b;
    border-radius: 10px;
    padding: 10px;
    z-index: 2;
    min-width: 200px;
}

.popout-title {
    margin: 0;
    font-family: 'Arimo', sans-serif;
    font-size: 16px;    
    padding-bottom: 5px;
    border-bottom: 1px solid #2b2b2b;
    text-align: center;
}

/* END UTILITY - POPOUT */

/* HOME PAGE */
.page-container {
    display: block;
}

.hero-container {
    background: linear-gradient(100deg, #5914a8 0%, #872696 100%); 
    position: relative;
    height: 800px;
}

.hero-content {
    max-width: 1220px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px auto;
    padding: 8rem 16px;
    font-family: 'Inter', sans-serif;
}

.hero-title {
    font-family: 'Inter', sans-serif;
}

.hero-text-container {
    max-width: 600px;
}

.hero-wrapper {
    display: flex;
    flex-direction: row;
}
.hero-text {
    font-size: 2em;
}

.hero-text h1 {
    margin: 0;
}

.hero-curved-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.hero-curved-bottom .shape-fill {
    fill: #131313;
}

.features-container {
    position: relative;
}

.features-content {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0px auto;
    padding: 50px;
}

.features-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.features-section-title {
    width: 450px;
    font-size: 1.25em;
}

.features-section-title-right {
    width: 450px;
    font-size: 1.25em;
    margin-left: auto;
}

.features-section-image {
    margin-left: auto;
    width: 460px;
}

.features-section-image-left {
    width: 460px;
}

.bottom-container {
    background: linear-gradient(100deg, #5914a8 0%, #872696 100%); 
    position: relative;
}

.bottom-content {
    max-width: 1220px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px auto;
    padding: 8rem 16px;
    font-family: 'Inter', sans-serif;
    font-size: 2em;
}

.bottom-content h1 {
    text-align: center;
    margin: 0;
}
/* END HOME PAGE */

/* AUTH */
.auth-container {
    font-size: 16px;
    max-width: 350px;
    margin: 0 auto;
    padding-top: 100px;
    font-family: 'Arimo', sans-serif;
}

.auth-content {
    padding: 1.5rem;
    border: 1px solid #3f3f3f;
    border-radius: 5px;
    background-color: #18181a;
}

.auth-content h1 {
    margin: 0;
    font-family: 'Inter', sans-serif;
}

.auth-content input {
    outline: none;
    border: 1px solid #5a5a5a;
    box-sizing: border-box;
    font-family: 'Arimo', sans-serif;
    border-radius: 5px;
    background-color: #2d2d2f;
    color: #e4e4e4;
    padding: 12px 10px;
    margin: 8px 0;
    font-size: 16px;
    width: 100%;
    transition: background-color .3s;
}

.auth-content button {
    width: 100%;
    margin-top: 30px;
}

.auth-link {
    color: rgb(0, 132, 255);
    text-decoration: none;
}

.auth-alternate-container {
    padding-top: 25px;
}

.auth-alternate-content {
    padding: 0.5rem 1.5rem;
    border: 1px solid #3f3f3f;
    border-radius: 5px;
    background-color: #18181a;
}

.auth-toast {
    background-color: #bf3b3498;
    padding: 14px 16px;
    text-align: center;
    margin: 20px 0 20px 0;
    border: 1px solid #bf3b34;
    border-radius: 5px;
}

.auth-toast p {
    margin: 0;
}
/* END AUTH */

/* APP SECTION */
/* BIG STUFF */
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.app-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: 'Arimo', sans-serif;
}

.outliner {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    min-height: 100%;
    width: 500px;
    background: #131313;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.outliner-collapsed {
    display: flex;
    position: absolute;
    height: 100%;
    min-height: 100%;
    width: 500px;
    background-color: #131313;
    transform: translate3d(-480px, 0, 0);
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

.outliner-right {
    position: absolute;
    left: 100%;
    height: 100%;
    border-right: 1px solid #2b2b2b;
    width: 20px;
    transform: translateX(-20px);
}

.outliner-container {
    overflow: hidden auto;
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
}

.outliner-widget {
    padding: 10px 0px 10px 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #19191c;
    box-shadow: 3.5px 7px 7px rgba(0,0,0,.2);
}

.outliner-widget-title {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #2f2f2f;
    margin-left: 5px;
    margin-right: 5px;
}

.widget-icon {
    margin-left: 5px;
    border-radius: 5px;
    padding: 2px;
}

.outliner-widget-title h2 {
    margin: 0px;
    margin-left: 10px;
    font-size: 26px;
}

.collapse-button {
    width: 26px;
    height: 26px;
    transform: translate(7px, 22px);
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: #343434;
    box-shadow: 3.5px 7px 7px rgba(0,0,0,.2);
    color: #fff;
    font-weight: bold;
    padding: 0;
    cursor: pointer;
    border: 1px solid #343434;
}

.content-wrapper {
    display: flex;
    height: 100%;
    min-height: 100%;
    padding-left: 530px;
    padding-right: 30px;
    transition: padding-left 0.3s ease-in-out;
    background-color: #19191c;
}

.content-wrapper-full {
    display: flex;
    height: 100%;
    min-height: 100%;
    padding-left: 50px;
    padding-right: 30px;
    transition: padding-left 0.3s ease-in-out;
    background-color: #19191c;
}

@media (max-width: 1200px) {
    .outliner {
        width: 400px;
    }

    .outliner-collapsed {
        width: 400px;
        transform: translate3d(-380px, 0, 0);
    }

    .content-wrapper {
        padding-left: 430px;
    }
}

@media (max-width: 540px) {
    .outliner {
        width: 350px;
    }

    .outliner-collapsed {
        width: 350px;
        transform: translate3d(-330px, 0, 0);
    }

    .content-wrapper {
        padding-left: 0px;
    }
}

.calendar {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.calendar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
}

.calendar-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #3f3f3f;
}

.calendar-control-button {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #19191c;
    cursor: pointer;
    background-color: #19191c;
}

.calendar-control-button:hover {
    background-color: #19191c;
    border: 1px solid #3f3f3f
}

.calendar-control-today-button {
    border: 1px solid #3f3f3f;
    padding: 7.5px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.calendar-control-today-button:hover {
    background-color: #19191c;
}

.calendar-header h1 {
    margin: 15px 0 15px 0;
}

.planner-calendar {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-auto-rows: minmax(0, 1fr);
    padding: 0 0 30px 0;
    margin: 0;
    list-style: none;
    align-content: stretch;
    justify-content: stretch;
    flex-grow: 1;
}

.popout-calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 0;
    padding: 17px;
    list-style: none;
    font-family: 'Arimo', sans-serif;
}

.popout-calendar p {
    margin: 0;
    text-align: center;
}

.calendar-day {
    list-style: none;
    display: flex;
    align-items: flex-start;
    border: 1px solid #3f3f3f;
    color: white;
    overflow: hidden;
}

.calendar-day-active {
    border: 1px solid #248aff;
    background-color: #248aff09;
}

.calendar-day-title-container {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    margin: 10px 0 0 0;
    padding: 0;
}

.calendar-day-title {
    margin: 0 0 10px 10px;
    font-size: 14px;
}

.calendar-day-content {
    height: 100%;
    width: 100%;
}

.calendar-day-content h2 {
    padding: 10px;
    margin: 0;
}

.pop-calendar-day {
    list-style: none;
    display: flex;
    align-items: flex-start;
    color: white;
    padding: 10px;
    width: 20px;
    height: 20px;
}

.pop-calendar-day-active {
    border: 1px solid #248aff;
    border-radius: 10px;
    padding: 9px !important;
}

.pop-calendar-day-between {
    border: 1px solid #248aff;
    border-radius: 10px;
    padding: 9px !important;
}

.pop-calendar-day-content {
    height: 100%;
    width: 100%;
}

.pop-calendar-day-content p {
    text-align: center;
    margin: 0 auto;
    font-family: 'Arimo', sans-serif;
}

.calendar-day-content h1 {
    margin: 0 auto;
}

.inactive-month {
    color: rgba(92, 92, 92, 0.404);
}

.calendar-first-day {
    grid-column-start: 5;
}

.tasks-list-content {
    list-style-type: none;
    padding: 0px 10px 0px;
    margin-top: 5px;
}

.composer-btn {
    margin-left: 10px;
}

.task-composer {
    padding: 0px 10px 0px 10px;
}

.task-composer-controls {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.task-title-input {
    width: 100%;
    box-sizing: border-box;
    background-color: #202024;
    color: #e4e4e4;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    font-family: 'Arimo', sans-serif;
    border-radius: 6px;
    border: none;
    outline: none;
}

.task {
    border-bottom: 1px solid #2b2b2b;
    padding: 5px 0px;
    display: flex;
    align-items: center;
}

.task-color {
    height: 25px;
    width: 5px;
    border-radius: 3px;
    margin-right: 3px;
}

.task-checkbox {
    position: relative;
    min-width: 20px;
    min-height: 20px;
    border: 2px solid #2b2b2b;
    border-radius: 5px;
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 6px;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

.task-checkbox:checked {
    color: #fff;
    background:#4494ff;
    border-color: #4494ff;
}

.task-checkbox:checked::before {
    opacity: 1;
}

.task-checkbox::before {
    position: absolute;
    content: "";
    display: block;
    top: 1px;
    left: 5px;
    width: 5px;
    height: 10px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}

.task-title-container {
    display: flex;
    overflow-wrap: break-word;
}

.task-title-button {
    background: none;
    font-size: 18px;
    font-weight: 500;
    border: none;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    cursor: pointer;
    outline: none;
}

.big-checkbox {
    min-width: 25px;
    min-height: 25px;
    border: 2px solid #3d3d3d;
}

.big-checkbox::before {
    top: 1px;
    left: 6px;
    width: 7px;
    height: 13px;
    border-width: 0px 3px 3px 0;
}

.task-modal-title-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.task-modal-title {
    box-sizing: border-box;
    width: 100%;
    overflow-wrap: break-word;
    overflow: hidden;
    height: 33px;
    border-radius: 3px;
    background: none;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 24px;
    font-weight: 500;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    border: none;
    resize: none;
    outline: none;
    margin-bottom: 1px;
    margin-left: 5px;
}

.description-header {
    margin: 0;
    font-family: 'Arimo', sans-serif;
    font-weight: 500;
    font-size: 18px;
    padding: 10px 5px;
}

.task-modal-description {
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    overflow-wrap: break-word;
    border-radius: 10px;
    background-color: #19191c;
    padding: 10px;
    font-size: 16px;
    font-family: 'Arimo', sans-serif;
    color: #e4e4e4;
    border: none;
    resize: none;
    outline: none;
}

.task-modal-options-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 0px 7px 0px;
    gap: 10px;
}

.task-modal-option {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.modal-controls-container {
    display: flex;
    gap: 1em;
    position: absolute;
    bottom: 25px;
    right: 25px;
}

.task-color-grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 5px 0px;
}

.task-color-grid-item {
    padding: 5px;
}

.task-color-grid-button {
    width: 40px;
    height: 30px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.due-date-info-container {
    margin-top: 10px;
    font-family: 'Arimo', sans-serif;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.due-date-info-container .btn{
    margin-top: 10px;
    width: 100%;
}

.due-date-checkbox-container {
    display: flex;
    align-items: center;
    margin: 5px 0 10px 0;
}

.date-input {
    outline: none;
    border: 1px solid #5a5a5a;
    box-sizing: border-box;
    font-family: 'Arimo', sans-serif;
    border-radius: 5px;
    background-color: #2d2d2f;
    color: #e4e4e4;
    padding: 6px;
    font-size: 16px;
    width: 125px;
    margin: 4px;
    transition: background-color .3s;
}

.time-input {
    outline: none;
    border: 1px solid #5a5a5a;
    box-sizing: border-box;
    font-family: 'Arimo', sans-serif;
    border-radius: 5px;
    background-color: #2d2d2f;
    color: #e4e4e4;
    padding: 6px;
    font-size: 16px;
    width: 90px;
    margin: 4px;
    transition: background-color .3s;
}

.date-input:disabled {
    background-color: #19191c;
    color: #5a5a5a;
}

.time-input:disabled {
    background-color: #19191c;
    color: #5a5a5a;
}

.due-date-input:disabled {
    background-color: #19191c;
    color: #5a5a5a;
}

/* EVENTS */
.events-list-content {
    list-style-type: none;
    padding: 0px 10px 0px;
    margin-top: 10px;
}

.event {
    padding: 5px 0px;
    display: flex;
    align-items: center;
}

.event-color {
    height: 50px;
    width: 5px;
    border-radius: 3px;
    margin-right: 10px;
}

.event-title-button {
    display: block;
    font-size: 18px;
    font-weight: 500;
    border: none;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    cursor: pointer;
}

.event-title-button p, h5 {
    margin: 0;
}

.event-title-button h5 {
    font-weight: 400;
}

.event-date-container {
    display: flex;
    align-items: center;
}

.event-date-selected {
    outline: 2px solid #248aff;
    outline-offset: -2px;
}

.calendar-event {
    display: flex;
    flex-direction: row;
    background-color: #248aff26;
    border: 1px solid #248aff;
    border-radius: 5px;
    font-size: 12px;
    font-family: 'Arimo', sans-serif;
    padding: 3px;
    overflow: hidden;
    white-space: nowrap;
    margin: 0px 7px 5px 7px;
    box-shadow: 3.5px 7px 7px rgba(0,0,0,.2);
}

.calendar-event-title-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.calendar-event-date {
    color: #e4e4e4cc
}

.calendar-event p {
    margin: 0;
}

.start-event-day {
    margin: 0px 0px 5px 7px;
    border-right: none;
    border-radius: 5px 0px 0px 5px;
}

.median-day {
    margin: 0px 0px 5px 0px;
    border-radius: 0px;
    border-left: none;
    border-right: none;
}

.end-event-day {
    margin: 0px 7px 5px 0px;
    border-left: none;
    border-radius: 0px 5px 5px 0px;
}

.median-day p, .end-event-day p {
    visibility: hidden;
}

.event-blank {
    visibility: hidden;
}

.dashboard-container {
    position: relative;
    background-color: #131313;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    height: 100%;
}

.dashboard-content {
    display: flex;
    font-family: 'Arimo', sans-serif;
    flex-direction: column;
    margin: 0 auto;
    width: 1025px;
}

.dashboard-title {
    border-bottom: 1px solid #2f2f2f;
    padding-bottom: 10px;
    margin: 60px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashboard-title h1 {
    flex-grow: 1;
    margin: 0;
}

.dashboard-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1025px;
    gap: 25px;
}

@media (max-width: 1075px) {
    .dashboard-content {
        width: 675px;
    }

    .dashboard-cards-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .insights-widgets-container {
        flex-direction: column !important;
    }

    .insights-column {
        max-width: 100% !important;
    }
}

@media (max-width: 725px) {
    .dashboard-content {
        width: 325px;
    }

    .dashboard-cards-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

.dashboard-card {
    display: flex;
    flex-direction: column;
    width: 325px;
    background-color: #19191c;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 7px 3px rgba(0,0,0,.2);
}

.dashboard-card-background-container {
    height: 200px;
    background-color: #6ab556;
}

.dashboard-card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-top: 1px solid #2c2c2c;
    gap: 10px;
}

.dashboard-card-profile-picture {
    width: 40px;
    margin: 10px;
}

.dashboard-task {
    display: flex;
    flex-direction: row;
}

.insights-widgets-container {
    margin: 0px 0px 60px 0px;
    display: flex;
    flex-direction: row;
    max-width: 1025px;
    gap: 25px;
}

.insights-column {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    flex-grow: 1;
}

.dashboard-widget {
    width: 100%;
    background-color: #19191c;
    padding: 10px 0px 10px 0px;
    box-shadow: 2px 7px 3px rgba(0,0,0,.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.dashboard-widget-title {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #2f2f2f;
    margin-left: 5px;
    margin-right: 5px;
}

.dashboard-widget-title h2 {
    margin: 0px 0px 0px 10px;
}

.dashboard-widget-content {
    padding: 5px 10px 10px 10px;
}

.no-elements-banner-container {
    text-align: center;
}

.no-elements-header {
    margin: 0;
    padding: 10px;
}

.no-elements-text {
    margin: 0;
    padding: 10px;
}

.account-management-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    background-color: #131313;
    width: 100%;
    height: 100%;
    font-family: 'Arimo', sans-serif;
}

.account-management-wrapper {
    display: flex;
    flex: 1 1 800px;
    height: 100%;
    background-color: #19191c;
}

.account-management-content {
    padding: 60px 25px 60px 50px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: 700px;
    overflow: hidden auto;
}

.account-management-sidebar {
    display: flex;
    flex: 1 0 250px;
    min-width: 200px;
    justify-content: flex-end;
}

.account-management-sidebar-content {
    padding: 60px 25px;
}

.account-management-title {
    border-bottom: 1px solid #2f2f2f;
    padding-bottom: 10px;
    margin: 0;
}

.account-management-subtitle {
    margin: 15px 0px 5px 0px;
}

.account-management-sidebar-links {
    width: 200px;
}

.account-management-sidebar-links a {
    color: #e4e4e4;
    text-decoration: none;
}

.account-management-sidebar-links p {
    border: none;
    outline: none;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    padding: 10px 8px;
    margin: 5px 0px;
    font-size: 16px;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    transition: background-color .1s;
}

.account-management-sidebar-links p:hover {
    background-color: #2f2f2f;
    display: flex;
    align-items: center;
}

.account-management-active {
    background-color: #2f2f2f;
    display: flex;
    align-items: center;
}

.account-management-input {
    outline: none;
    border: 1px solid #2f2f2f;
    box-sizing: border-box;
    font-family: 'Arimo', sans-serif;
    border-radius: 5px;
    background-color: #1c1c20;
    color: #e4e4e4;
    padding: 12px 10px;
    margin: 8px 0;
    font-size: 16px;
    width: 400px;
    transition: background-color .3s;
}

.dashboard-controls-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.name-item-input {
    overflow-wrap: break-word;
    overflow: hidden;
    height: 32px;
    padding: 2px 10px 1px 10px;
    background: none;
    font-size: 18px;
    font-weight: 500;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    outline: none;
    margin: 10px 0px;
    border: 1px solid #19191c;
    border-radius: 5px;
}

.name-item-input:focus {
    border: 1px solid #2f2f2f;
    border-radius: 5px;
}

.planner-name-input {
    font-weight: bold;
    border: 1px solid #131316;
    padding: 2px 5px 1px 5px;
}

.insights-controls-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.planner-manager {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.planner-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.inline-management-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.inline-management-button {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    background-color: #131316;
    border: none;
}

.share-modal {
    font-family: 'Arimo', sans-serif;
    min-height: 125px;
    margin-top: 15%;
}

.share-modal-title {
    margin: 5px 0px 0px 0px;
    font-size: 24px;
    font-family: 'Arimo', sans-serif;
}

.share-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.share-input {
    overflow-wrap: break-word;
    overflow: hidden;
    height: 32px;
    padding: 2px 10px 1px 10px;
    background: none;
    font-size: 18px;
    font-weight: 500;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    outline: none;
    margin: 30px 0px;
    border: 1px solid #242424;
    border-radius: 5px;
    flex-grow: 1;
}

.column-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.manager-title-input {
    box-sizing: border-box;
    width: 100%;
    overflow-wrap: break-word;
    overflow: hidden;
    height: 33px;
    border-radius: 3px;
    background: none;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 24px;
    font-weight: 500;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    border: none;
    resize: none;
    outline: none;
    margin-bottom: 1px;
    margin-left: 5px;
}

.manager-options-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 0px 7px 0px;
    gap: 10px;
}

.manager-option {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.manager-modal {
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;
    flex: 1;
    margin: 100px 10px;
    max-width: 900px;
    max-height: 800px;
    border-radius: 10px;
    background-color: #131313;
    border: 1px solid #2b2b2b;
    height: 100%;
}

.planner-management-container {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    background-color: #131313;
    width: 100%;
    flex: 1;
    font-family: 'Arimo', sans-serif;
    border-radius: 10px;
}

.planner-management-content {
    padding: 25px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: hidden auto;
    background-color: #19191d;
    border-radius: 0px 10px 10px 0px;
}

.planner-management-sidebar {
    padding-top: 25px;
    display: flex;
    min-width: 200px;
    justify-content: flex-end;
}

.planner-management-sidebar-content {
    padding: 0px 15px;
}

.planner-management-title {
    border-bottom: 1px solid #2f2f2f;
    padding-bottom: 10px;
    margin: 0;
}

.planner-management-subtitle {
    margin: 15px 0px 5px 0px;
}

.planner-management-sidebar-links {
    width: 200px;
}

.planner-management-sidebar-links a {
    color: #e4e4e4;
    text-decoration: none;
}

.planner-management-sidebar-links p {
    border: none;
    outline: none;
    color: #e4e4e4;
    font-family: 'Arimo', sans-serif;
    padding: 10px 8px;
    margin: 5px 0px;
    font-size: 16px;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    transition: background-color .1s;
}

.planner-management-sidebar-links p:hover {
    background-color: #2f2f2f;
    display: flex;
    align-items: center;
    
}

.planner-management-active {
    background-color: #2f2f2f;
    display: flex;
    align-items: center;
    
}

.planner-management-input {
    outline: none;
    border: 1px solid #2f2f2f;
    box-sizing: border-box;
    font-family: 'Arimo', sans-serif;
    border-radius: 5px;
    background-color: #1c1c20;
    color: #e4e4e4;
    padding: 12px 10px;
    margin: 8px 0;
    font-size: 16px;
    width: 300px;
    transition: background-color .3s;
}

.links-divider {
    font-size: 12px;
    margin: 15px 0px;
    padding-left: 5px;
    border-bottom: 1px solid #2b2b2b;
}

.row-content {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.management-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin: 0px 0px 0px auto;
}
